<template>
  <b-card class="mb-0">
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <b-form-input class="" v-model="search" type="search" placeholder="Search" />
            </b-col>
            <b-button variant="outline-primary" @click="$router.push('/activities/create')">
              + Activity
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-table id="activitieTable" responsive show-empty :items="filterActivities" :key="filterActivities.id"
      :fields="activityHeader" :per-page="perPage" :current-page="currentPage">

      <!-- Responsible -->
      <template #cell(responsible)="data">
        <ResponsibleAvatar :activity="data" />
      </template>

      <!-- Status -->
      <template #cell(status)="data">
        <b-badge :variant="`light-${categoryStatus(data.item.status)}`">
          {{ data.item.status.toUpperCase() }}
        </b-badge>
      </template>

      <!-- Price -->
      <template #cell(price)="row">
        <PriceActivity :activity="row.item" />
      </template>

      <!-- Members -->
      <template #cell(members)="data">
        <MembersAvatar :activity="data" />
      </template>

      <!-- Production Time -->
      <template #cell(production_time)="data">
        {{ calculateTime(data.item) }}
      </template>

      <!-- Actions -->
      <template #cell(actions)="row">
        <b-row>
          <b-col cols="2">
            <b-button class="p-0" size="sm" variant="flat" v-b-tooltip.hover.top="'details'" title="Details"
              @click="clickDetails(row)">
              <feather-icon icon="FileIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-if="currentUser.roles[0].name !== 'EMPLOYEE'" v-b-tooltip.hover title="Edit"
              variant="flat" size="sm" @click="clickEdit(row)">
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-if="currentUser.roles[0].name !== 'EMPLOYEE'" v-b-tooltip.hover title="Delete"
              variant="flat" size="sm" @click="clickModal(row)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- Progress bar -->
      <template #cell(progress)="data">
        <div class="progress-wrapper">
          {{ Math.round(Number(calculateProgress(data.item))).toString() + '%' }}
          <b-progress :value="calculateProgress(data.item)" max="100" />
        </div>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="activitieTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BTable,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BProgress,
  BCol,
  BPagination,
  BBadge,
  VBTooltip
} from 'bootstrap-vue'
import { activityHeader } from '@/utils/headers'
import MembersAvatar from '@/components/atoms/activity/MembersAvatar.vue'
import PriceActivity from '@/components/atoms/activity/PriceActivity.vue'
import ResponsibleAvatar from '@/components/atoms/activity/ResponsibleAvatar.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    vSelect,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BBadge,
    BProgress,
    MembersAvatar,
    PriceActivity,
    ResponsibleAvatar,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      activityHeader,
      search: "",
      perPage: 10,
      progress: 0,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.usersConfig.currentUser
    },

    users() {
      return this.$store.state.usersConfig.users
    },

    activities() {
      return this.currentUser.roles[0].name === 'HEAD_OF_ORGANIZATION'
        ? this.$store.getters.getOrgActivities(this.currentUser.id)
        : this.currentUser.roles[0].name === 'HEAD_OF_DEPARTMENT'
          ? this.$store.getters.getDepartamentActivities(this.currentUser.id)
          : this.currentUser.roles[0].name === 'PROJECT_MANAGER'
            ? this.$store.getters.getProjManagerActivities(this.currentUser.id)
            : this.currentUser.roles[0].name === 'EMPLOYEE'
              ? this.$store.getters.getEmployeeActivities(this.currentUser.id)
              : this.$store.state.activityConfig.activities
    },

    filterActivities() {
      return this.activities.filter((activitie) => {
        return (
          activitie.title.toLowerCase().includes(this.search.toLowerCase()) ||
          activitie.status.toLowerCase().includes(this.search.toLowerCase())
        );
      })
    },
    rows() {
      return this.filterActivities.length
    }
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('getAllUsers'),
      this.$store.dispatch('getAllActivities'),
    ])
  },

  methods: {
    formatTime(totalTime) {
      const segundos = Math.floor(totalTime % 60);
      const minutos = Math.floor((totalTime / 60) % 60);
      const horas = Math.floor(totalTime / 3600);
      return [horas, minutos, segundos].map(this.adicionarZero).join(':');
    },
    adicionarZero(valor) {
      return valor.toString().padStart(2, "0");
    },
    calculateTime(activity) {
      let timer = 0;

      activity.tasks.forEach(task => {
        timer += task.owners.reduce((a, b) => a + Number(b.timer ?? 0), 0)
      })

      activity.tasksActivities.forEach(task => {
        timer += task.owners.reduce((a, b) => a + Number(b.timer ?? 0), 0)
      })

      return this.formatTime(timer)
    },
    categoryStatus(categ) {
      const statusMap = {
        activated: 'primary',
        completed: 'success',
        cancelled: 'danger',
        pending: 'warning'
      };
      return statusMap[categ] || 'primary'
    },

    async deleteActivity(id) {
      this.$store.dispatch('deleteActivityById', id)
    },

    clickModal(row) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete that line.', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteActivity(row.item.id)
          }
        })
    },

    calculateProgress(activity) {
      const tasks = activity.tasks.concat(activity.tasksActivities)
        .filter(task => task.status !== 'canceled')

      const n = 100 / tasks.length

      return tasks.reduce((p, task) => {
        if (task.status === 'approved') return p + n
        if (task.status === 'waiting_for_aproval') return p + (n / 2) + (n / 4)
        if (task.status === 'to_review') return p + n / 2
        if (task.status === 'to_do' || task.status === 'peding') return p
        return p + n / 4
      }, 0)
    },

    clickEdit(row) {
      this.$router.push('/activities/edit/' + row.item.id)
    },

    clickDetails(row) {
      this.$router.push('/activities/' + row.item.id)
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
